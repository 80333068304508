<template>
    <div class="ScheduleWidget">
        <h1 v-if="step === 0" class="ScheduleWidget-Title">Contáctanos</h1>
        <h1 v-else class="ScheduleWidget-Title">Agendar una cita</h1>

        <form
            id="contact-form"
            class="ScheduleWidget-Form"
            action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
            ref="form"
            method="POST"
        >
            <input type=hidden name="oid" value="00Df20000019tKD">
            <input type="hidden" name="retURL" :value='returnUrl' />
            <input id="lead_source" name="lead_source" type=hidden value="6"/> <!--Prospeccion-->
            <input id="00Nf200000DETwY" name="00Nf200000DETwY" type=hidden value="7"/> <!--Medio-->
            <input id="00Nf2000003TfFH" name="00Nf2000003TfFH" type="hidden" :value="salesvalue" />
            <input id="00Nf2000003UJfn" name="00Nf2000003UJfn" type=hidden value="1"/> <!--Inmueble-->
            <fieldset v-show="step === 0" class="ScheduleWidget-FormGroup" >
                <form-input-text
                    class="ScheduleWidget-FormField"
                    id="first_name"
                    label="Nombre *"
                    v-model="firstName"
                    v-validate="'required|alpha_spaces|min:1|max:60'"
                    :error="errors.first('Nombre')"
                />
                <div class="ScheduleWidget-FormRow">
                    <form-input-text
                        class="ScheduleWidget-FormField"
                        id="last_name"
                        label="Apellido"
                        v-model="lastName"
                        v-validate="'required|alpha_spaces|min:1|max:60'"
                        :error="errors.first('Apellido')"
                    />
                    <!--<form-input-text
                        class="ScheduleWidget-FormField"
                        id="00Nf200000DETwN"
                        label="Apellido Materno"
                        v-model="maternalName"
                    />-->
                </div>
                <form-input-text
                    class="ScheduleWidget-FormField"
                    id="email"
                    label="Correo electrónico *"
                    v-validate="'required|email'"
                    v-model="email"
                    :error="errors.first('Email')"
                />
                <form-input-text
                    class="ScheduleWidget-FormField"
                    id="phone"
                    label="Número de teléfono (10 dígitos) *"
                    placeholder="5566778899"
                    maxlength="10"
                    v-validate="'numeric|min:10|max:10'"
                    v-model="phone"
                    :error="errors.first('Teléfono')"
                />
                <template v-if="salesvalue == 'a0Wf2000009CNRHEA4'">
                <form-select
                    ref="howHearSelect"
                    title="Interesado en"
                    class="ScheduleWidget-FormField"
                    name="00Nf2000003UJfs"
                    id="00Nf2000003UJfs"
                    label="Interesado en"
                    :options="interestedIn"
                />
                </template>
                <template v-else>
                    <input id="00Nf2000003UJfs" name="00Nf2000003UJfs" type=hidden value="Compra"/> <!--Interesado en-->
                </template>
                <!--<form-checkbox
                    v-show="false"
                    ref="scheduleCheckbox"
                    v-model="schedule"
                    class="ScheduleWidget-FormField"
                    label="Quiero agendar una cita"
                />-->
            </fieldset>
            <!-- <fieldset
                v-show="step === 1"
                class="ScheduleWidget-FormGroup"
            >
                <input
                    v-if="step === 1"
                    type="hidden"
                    id="00N2f000000uTK7"
                    name="00N2f000000uTK7"
                    size="20"
                    :value='scheduledDateTime'
                />
                <form-select
                    ref="complexSelect"
                    label="Complejo:"
                    id="00Nf200000DETwS"
                    :options="complexOptions"
                />
                <date-picker
                    v-if="step === 1"
                    ref="datePicker"
                />
                <form-select
                    v-if="step === 1"
                    ref="timeSelect"
                    label="Horario:"
                    :options="hoursOptions"
                />
            </fieldset> -->
        </form>
        <div class="ScheduleWidget-Navigation">
            <flat-button
                v-if="step === 0"
                class="ScheduleWidget-NextButton"
                iconId="icon-arrow-right"
                @click.native="onNextClick"
            >
                {{ schedule ? "Siguiente" : "Enviar" }}
            </flat-button>
            <flat-button
                v-if="step === 1"
                class="ScheduleWidget-PrevButton"
                iconId="icon-arrow-left"
                iconLeft
                @click.native="step = 0"
            >
                Anterior
            </flat-button>
            <flat-button
                v-if="step === 1"
                class="ScheduleWidget-SubmitButton"
                iconId="icon-arrow-right"
                el="div"
                @click.native="postForm"
            >
                Enviar
            </flat-button>
        </div>
    </div>
</template>

<script>
import Api from '@/app/utils/Api'
import DatePicker from '@/app/components/DatePicker.vue'
import FlatButton from '@/app/components/buttons/FlatButton.vue'
import ComplexUtils from '@/app/mixins/ComplexUtils'
import FormSelect from '@/app/components/form/FormSelect.vue'
import FormInputText from '@/app/components/form/FormInputText.vue'
//import FormCheckbox from '@/app/components/form/FormCheckbox.vue';

export default {
    name: 'ScheduleWidget',
    components: {
        DatePicker,
        FormInputText,
        FormSelect,
        FlatButton,
        //FormCheckbox,
    },
    mixins: [
        ComplexUtils,
    ],
    props: {
        items: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            step: 0,
            transactionId: '',
            scheduledDateTime: '',
            firstName: '',
            lastName: '',
            maternalName: '',
            phone: '',
            email: '',
            schedule: false,
        }
    },
    mounted() {
        this.selectCurrentComplex()
        const { $route } = this
        console.log("ScheduleWidget", $route.params.complex)
        console.log("ScheduleWidget", this.items)
    },
    methods: {
        async onNextClick() {
            const { $validator, schedule } = this
            const valid = await $validator.validate()
            if (valid) {
                if (schedule) {
                    this.step = 1
                } else {
                    this.postForm()
                }
            }
        },

        async postForm() {
            const { form, complexSelect, datePicker, timeSelect } = this.$refs
            const getFormJSON = (f) => {
                const data = new FormData(f)
                return Array.from(data.keys()).reduce((result, key) => {
                    result[key] = data.get(key)
                    return result
                }, {})
            }
            const contactFields = {
                data: getFormJSON(form),
            }
            let scheduleFields = {}
            if (this.schedule) {
                scheduleFields = {
                    complex: complexSelect.selected,
                    date: datePicker.date,
                    // strip PM and AM posfixes from time
                    time: timeSelect.selected.replace(/\s(PM|AM)/g, ''),
                }
            }
            try {
                const response = await Api.postContact({
                    ...contactFields,
                    ...scheduleFields,
                })
                if (response.data.code) {
                    this.submitForm(response.data.code)
                    //this.goToThankYouPage(response.data.code)
                }
            } catch (e) {
                /* eslint-disable-next-line */
                console.error('An error ocurred while posting data:', e)
                /* eslint-disable-next-line */
                console.error('Form Data:', {
                    ...contactFields,
                    ...scheduleFields,
                })
            }
        },
        submitForm(transactionId) {
            const { form, datePicker, timeSelect, scheduleCheckbox } = this.$refs
            this.transactionId = transactionId
            if (this.step === 1) {

                // Format date/time as dd/mm/yyyy-hh:mm to set hidden input attribute
                const fmtDate = datePicker.formatSelectedDate('d/m/Y')
                this.scheduledDateTime = `${fmtDate} ${timeSelect.selected}`

                // exclude inputs from submitting
                scheduleCheckbox.$el.querySelector('input').removeAttribute('name')
                datePicker.$el.querySelector('input').removeAttribute('name')
                timeSelect.$el.querySelector('select').removeAttribute('name')
            }
            this.$nextTick(() => {
                document.querySelector("button.ScheduleWidget-NextButton").setAttribute("disabled", "disabled")
                form.submit()
            })
        },
        selectCurrentComplex() {
            const { complexSelect } = this.$refs
            complexSelect.selectByValue(this.complex.name)
            // complexSelect.selectByValue(this.complex.name.replace(/\s+/g, ''))
        },
    },
    computed: {
        hoursOptions() {
            return [
                { text: "10:00 a.m.", value: "10:00 AM" },
                { text: "11:00 a.m.", value: "11:00 AM" },
                { text: "12:00 p.m", value: "12:00 PM" },
                { text: "1:00 p.m.", value: "01:00 PM" },
                { text: "2:00 p.m.", value: "02:00 PM" },
                { text: "3:00 p.m.", value: "03:00 PM" },
                { text: "4:00 p.m.", value: "04:00 PM" },
                { text: "5:00 p.m.", value: "05:00 PM" },
                { text: "6:00 p.m.", value: "06:00 PM" },
            ]
        },
        howHearOptions0() {
            return [
                { value: "", text: "Ninguno" },
                { value: "Google", text: "Google" },
                { value: "Correo electrónico publicitario", text: "Correo electrónico publicitario" },
                { value: "Metros Cúbicos", text: "Metros Cúbicos" },
                { value: "Lamudi", text: "Lamudi" },
                { value: "Inmuebles 24", text: "Inmuebles 24" },
                { value: "iCasas", text: "iCasas" },
                { value: "Facebook", text: "Facebook" },
                { value: "Linkedin", text: "Linkedin" },
                { value: "Espectacular", text: "Espectacular" },
                { value: "Publicidad en vallas", text: "Publicidad en vallas" },
                { value: "Pasando por desarrollo", text: "Pasando por desarrollo" },
                { value: "Instagram", text: "Instagram" },
                { value: "Guía de Inmuebles", text: "Guía de Inmuebles" },
                { value: "Mercado libre", text: "Mercado libre" },
                { value: "Propiedades.com", text: "Propiedades.com" },
                { value: "Conoce la marca Be Grand", text: "Conoce la marca Be Grand" },
                { value: "Expo", text: "Expo" },
                { value: "Vive por la zona", text: "Vive por la zona" },
                { value: "Prospecto de empresa", text: "Prospecto de empresa" },
                { value: "Página Web Be Grand", text: "Página Web Be Grand" },
                { value: "No definido", text: "No definido" },
                { value: "Facebook | Instagram", text: "Facebook | Instagram" },
                { value: "Conoce la marca", text: "Conoce la marca" },
                { value: "Redes sociales", text: "Redes sociales" },
            ]
        },
        howHearOptions1() {
            return [
                { value: '', text: 'Ninguno' },
                { value: "Conoce la marca", text: "Conoce la marca" },
                { value: "Correo electrónico publicitario", text: "Correo electrónico publicitario" },
                { value: "Google", text: "Google" },
                { value: "Revista | Periódico", text: "Revista | Periódico" },
                { value: "Facebook | Instagram", text: "Facebook | Instagram" },
                { value: "Programmatics | Campañas", text: "Programmatics | Campañas" },
                { value: "Linkedin", text: "Linkedin" },
                { value: "Ninguna de las anteriores", text: "" },
            ]
        },
        interestedIn() {
            return [
                { value: 'Compra', text: 'Compra' },
                { value: "3", text: "Compra rentando" },
            ]
        },
        complexOptions() {
            const { complexes } = this
            return complexes.map(complex => ({
                text: complex.name,
                value: complex.name //.replace(/\s+/g, '')
            }))
        },
        returnUrl() {
            const { transactionId, $router, $route } = this
            // return `https://dev-begrand.fooprojects.com/departamentos-en-venta/alto-polanco/cita/gracias?transaction_id=${transactionId}`
            /* form submit wont redirect to localhost */
            const pathRoute = $router.resolve({
                name: 'schedule-thank-you',
                params: $route.params,
                query: {
                    ...$route.query,
                    transaction_id: transactionId,
                },
            }).href

            return `${window.location.origin}${pathRoute}`
        },
        salesvalue() {
            const { $route } = this
            const item = this.items.find(item => item.slug === $route.params.complex)
            return item.salesvalue
        },
    },
}
</script>

<style src="styles/components/ScheduleWidget.styl" lang="stylus"></style>
